<template>
  <div>

    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">Daftar <b>Dokumen Pasien</b></div>
      <b-button
        squared
        variant="success"
        v-b-modal.modal-form
        v-if="manipulateBtn == true"
      >Tambah</b-button>
    </b-alert>

    <div
      class="row"
      v-if="page == 'list'"
    >
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <!-- <div class="d-flex justify-content-end">
              <div class="col-md-5">
                <b-form-group
                  id="patient"
                  label="Cari :"
                  label-for="patient"
                  description="Ketik disini untuk mencari folder"
                >
                  <b-form-input
                    id="patient"
                    v-model="find"
                    type="text"
                    placeholder="Cari Pasien..."
                    @change="getDocumentByName"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div> -->

            <!-- Filter -->
            <div class="row justify-content-end mt-n3">
              <div class="col-md-3 ">
                <b-button
                  squared
                  variant="success"
                  class="float-right"
                  @click="handleAllDocument"
                  v-if="showAllDocument == false"
                >Tampilkan Semua Dokumen</b-button>
                <b-button
                  squared
                  variant="success"
                  class="float-right"
                  @click="handleList"
                  v-if="showAllDocument == true"
                >Tampilkan Dokumen Hari Ini</b-button>
              </div>
              <div class="col-md-3">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text squared>
                      <b-icon-search></b-icon-search>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    type="text"
                    v-model="find"
                    placeholder="Cari Pasien..."
                    @change="getDocumentByName"
                  ></b-form-input>
                </b-input-group>
              </div>
            </div>

            <div
              class="row align-content-center"
              v-if="tableSide == false && dataSign == true"
            >
              <div class="col-12 text-center">
                <h6 class="text-danger">Dokumen Tidak Ditemukan</h6>
              </div>
              <div class="col-12 text-center">
                <small>Silahkan Silahkan Tambah Dokumen Terlebih Dahulu,<a
                    @click="handleList"
                    class="text-primary"
                  >Kembali Ke Tabel</a></small>
              </div>

            </div>

            <div v-if="tableSide == true ">
              <div
                class="row"
                v-if="tableHide == false"
              >
                <div class="col-md-12">
                  <h5>Dokumen hari ini :</h5>
                </div>
                <div class="col-md-12">
                  <Table
                    purpose="master"
                    :listData="listData"
                  ></Table>
                </div>
                <hr>
              </div>

              <!-- Sign for empty daily document -->
              <div
                class="row"
                v-if="tableHide == true"
              >
                <div class="col-12 text-center">
                  <h6 class="text-danger">Tidak Ada Dokumen Baru</h6>
                </div>
                <div class="col-12 text-center">
                  <small>Silahkan Cari Untuk Melihat Dokumen Pasien</small>
                </div>
              </div>

            </div>

            <div class="row">
              <div
                class="col-md-3"
                v-for="document in documents"
                :key="document.id"
              >
                <div class="card border-secondary card-document">
                  <div class="card-body pt-3 pb-2 d-flex justify-content-start align-items-center ">
                    <i class="fas fa-fw fa-3x fa-folder"></i>
                    <h6 class="font-weight-light d-block">
                      {{ document.patient_name }}
                    </h6>
                    <!-- <span>
                      {{ document.patient_id }}
                    </span> -->

                    <!-- <small>Tgl Upload:{{}}</small> -->
                  </div>
                  <div
                    class="card-footer pt-0 py-3"
                    style="border: none"
                  >
                    <!-- <b-button
                      size="sm"
                      class="btn-danger mx-1"
                      v-if="manipulateBtn ==  true"
                      @click="deleteData(document.patient_id)"
                    >
                      Hapus
                    </b-button> -->

                    <b-button
                      size="sm"
                      class="btn-primary mx-1"
                      @click="handleDetail(document.patient_id)"
                    >
                      Detail
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>

    <div
      class="row"
      v-if="page == 'detail'"
    >
      <div
        class="col-md-12"
        v-if="dataLoaded == true"
      >
        <Card>
          <template v-slot:body>
            <div class="row align-items-center">
              <div class="col-md-12 d-inline">
                <b-alert
                  show
                  variant="primary"
                >
                  <span
                    class="text-teal"
                    style="cursor: pointer;"
                    @click="handleList"
                  >Dokumen</span>
                  <i class="fas fa-fw fa-arrow-right text-light"></i>
                  <span class="text-teal">{{list.patient_name}}</span>
                  <i class="fas fa-fw fa-arrow-right text-light"></i>
                  <span class="font-weight-bold"> Detail</span>
                </b-alert>
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-3 my-2"
                v-for="(attachment, index) in attachments"
                :key="attachment.id"
              >
                <a
                  download="file"
                  :href="attachments[index]"
                >
                  <div class="card border-secondary card-document">
                    <div class="card-body pt-3 pb-2 d-flex justify-content-start align-items-center">
                      <i class="fas fa-fw fa-2x fa-file"></i>
                      <h6 class="font-weight-light">{{fileNameDetail[index]}}</h6>
                    </div>
                    <div
                      class="card-footer pt-0 py-3"
                      style="border: none"
                    ></div>
                  </div>
                </a>
              </div>
            </div>
            <b-button
              size="sm"
              class="btn-primary mt-3"
              @click="handleList"
              v-if="documentsLoad == false"
            >
              Kembali
            </b-button>
            <b-button
              size="sm"
              class="btn-primary mt-3"
              @click="handleAllDocument"
              v-if="documentsLoad == true"
            >
              Kembali
            </b-button>
          </template>
        </Card>
      </div>
    </div>

    <!-- Form Modal -->
    <Form />
  </div>
</template>

<script>
import Table from '@/component/patients/DocumentHistory.vue'
import module from "@/core/modules/CrudModule.js";
import Card from "@/view/content/Card.vue";
import Form from "@/component/patients/DocumentForm.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from 'moment';

export default {
  data() {
    return {
      // data
      tableSide: true, //for showing table 
      dataSign: false, //find or not data notification
      dataLoaded: false, //data load status
      documents: [],
      find: "",
      date: "",
      page: "list",
      list: "",
      listData: [],
      documentsLoad: false,
      tableHide: false, //for hide only table nnot side
      allDayDetail: "false",
      attachments: [],
      fileName: [],
      fileNameDetail: [],
      patientName: [],
      attachment: [],
      showAllDocument:false, //show all document status
      // access management
      manipulateBtn:false,
      fields: [
        {
          key: "no",
          label: "No",
          thClass: "text-center",
          tdClass: "text-center ",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "patient_name",
          label: "Nama Pasien",
          thClass: "text-center",
          tdClass: "text-center ",
          sortable: true,
        },
        {
          key: "document_name",
          label: "Nama Dokumen",
          thClass: "text-center",
          tdClass: "text-center ",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
    };
  },

  components: {
    Card,
    Form,
    Table
  },

  methods: {
    handleDate() {
      var currentDate = new Date()
      var day = currentDate.getDate()
      var month = currentDate.getMonth() + 1
      var year = currentDate.getFullYear()
      let date = day + "-" + month + "-" + year
      return date
    },
    handleAllDocument(){
      this.dataLoaded = false
      this.tableHide = true
      this.tableSide = false
      this.dataSign = false
      this.showAllDocument = true
      this.documentsLoad = true
      this.find = ""
      this.page = "list";
      this.getAllDocument()
    },
    handleDetail(id) {
      this.dataLoaded = false
      this.page = "detail";
      this.getDocument(id);
    },
    // handleToday(){
    //   this.dataLoaded = false
    //   this.tableHide = true
    //   this.tableSide = false
    //   this.dataSign = false
    //   this.showAllDocument = 
    //   this.find = ""
    //   this.page = "list";
    //   this.getAllDocument()
    // },
    handleList() {
      this.dataLoaded = false
      this.tableHide = false
      this.tableSide = true
      this.dataSign = false
      this.showAllDocument = false
      this.documentsLoad =false
      this.find = ""
      this.page = "list";
      this.getDocuments()
    },
    async getDocuments() {
      this.fileName = []
      this.patientName = []
      let response = await module.get("documents");
      this.documents = response;
      this.dataLoaded = true

      // table list daily

      this.listData = await module.get('documents-list')

      let a
      let b
      for (a = 0; a <= this.listData.length - 1; a++) {
        // explode files
        this.listData[a].files = this.listData[a].files.split(",")
        this.listData[a]["files_name"] = []
        for (b = 0; b <= this.listData[a].files.length - 1; b++) {
          // get name files
          let dataName = this.listData[a].files[b].split("$")
          this.listData[a]["files_name"].push(dataName[1].split("_").join(" "))
        }
      }
    },
    async getDocument(id) {
      this.list = []
      this.attachments = []
      this.fileNameDetail = []
      let listData = await module.get("documents/" + id + "/" + this.allDayDetail);
      this.list = listData[0];
      console.log(this.list)
      this.attachments = listData[0].files.split(",");

      let a
      for (a = 0; a <= this.attachments.length - 1; a++) {

        let data = this.attachments[a].split("$")
        this.fileNameDetail.push(data[1].split("_").join(" "))
        console.log("dump", this.fileNameDetail)
      }

      this.dataLoaded = true

      if(listData.length == 0){
        this.tableHide = true
      }else{
        this.tableHide =false
      }

    },
    async getDocumentByName() {
      if (this.find != "") {

        let response = await module.get("documents-name/" + this.find);
        if (response.length == 0) {
          this.tableSide = false
          this.tableHide = true
          this.dataSign = true

        } else {
          this.tableHide = true
          this.tableSide = false
          this.dataSign = false
        }
        this.allDayDetail = "true"
        this.documents = response;
        console.log("responses", response);
      } else {
        this.dataSign = false
        this.tableSide = true
        this.tableHide = false
        this.allDayDetail = "false"
        this.getDocuments()
      }
    },

    async getAllDocument() {
        let response = await module.get("documents-all");
        if (response.length == 0) {
          this.tableSide = false
          this.tableHide = true
          this.dataSign = true

        } else {
          this.tableHide = true
          this.tableSide = false
          this.dataSign = false
        }
        this.allDayDetail = "true"
        this.documents = response;
        this.dataLoaded = true
        console.log("responses", response);
    },
    // async getDocumentOnFolder() {
    //   if(this.find != ""){
    //     let response = await module.get("documents-name/" + this.find);
    //     this.documents = response;
    //     console.log(response);
    //   }else{
    //     this.getDocuments()
    //   }
    // },

    
    // access management
    async setActiveMenu(){

     let access_right_user = window.localStorage.getItem("access_right_display")
     let access_right = JSON.parse(access_right_user)
    
    let a
    for(a = 0; a < access_right.length; a++){
      console.log("looping")
      
      if(access_right[a] == "3008"){
        this.manipulateBtn = true
      }
      
    }
    },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Pasien" },
      { title: "Dokumen" },
    ]);
    this.getDocuments();
    this.setActiveMenu()
  },
};
</script>

<style scoped>
.card-document:hover {
  box-shadow: 15px 15px 15px 15px rgb(82 63 105 / 5%) !important;
}
</style>