<template>
  <div>

    <b-table
      striped
      hover
      :class="purpose == 'history' ? 'mt-5' : ''"
      :items="listData"
      :fields="fields"
    >
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <template #cell(created_at)="data">
        {{ handleDate(data.item.created_at) }}
      </template>

      <template #cell(patient_name)="data">
        {{ data.item.patient_name }}
      </template>

      <template #cell(display_check_date)="data">
        {{ data.item.display_check_date }}
      </template>

      <template #cell(document)="data">
        <div
          v-for="index in data.item.files.length"
          :key="index"
        >
          <a
            :href="data.item.files[index-1]"
            download="file"
          >
            <li>
              {{ data.item.files_name[index-1] }}
            </li>
          </a>
        </div>
      </template>
    </b-table>

  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import moment from "moment";

export default {
  name: "List",

  components: {
    Card,
  },
  data() {
    return {
      fields: [
        {
          key: "index",
          label: "#",
          // thClass: "text-center",
          // tdClass: "text-center ",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "patient_name",
          label: "Nama Pasien",
          thClass: "text-center",
          tdClass: "text-center ",
          sortable: true,
        },
        {
          key: "display_check_date",
          label: "Tanggal Diperiksa",
          sortable: true,
        },
        {
          key: "document",
          label: "Dokumen",
          sortable: true,
        },
        // { key: "actions", label: "Aksi" },
      ],
      items: [],
    };
  },
  props: {
    dataId: Number,
    purpose: String,
    listData: Array,
  },

  methods: {
    handleDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },

    // getList(){
    //   this.$emit('getDocuments')
    //   // this.items = this.listData
    //   console.log(this.listData);
    // }

  },

  mounted() {
  },
};
</script>

