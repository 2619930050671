<template>
  <div>

    <div>
      <b-modal
        id="modal-form"
        title="Tambah Dokumen"
        @ok="modalOnSubmit"
        @hidden="modalOnHide"
      >
        <b-form
          @submit.stop.prevent="formOnSubmit"
          enctype="multipart/form-data"
        >

          <!-- check date -->
          <b-form-group
            id="input-group-date"
          >
            <label for="input-email">Tanggal Diperiksa: <em class="text-muted">opsional</em></label>
            <b-form-datepicker
              id="input-date"
              locale="id"
              v-model="form.check_date"
            ></b-form-datepicker>
            <small class="text-danger">{{ error.check_date }}</small>
          </b-form-group>

          <!-- Patient Select -->
          <b-form-group
            id="input-group-patient"
            label="Pilih Pasien:"
            label-for="input-patient"
          >
            <b-form-input
              id="input-trigger-modal-patient"
              v-model="display.patient_name"
              placeholder="Pilih Pasien"
              readonly
              @click="$bvModal.show('modal-patient')"
            >
            </b-form-input>
            <small class="text-danger">{{ error.patient_id }}</small>
          </b-form-group>

          <!-- Patient Select
          <b-form-group
            id="input-group-patient"
            label="Pilih Pasien:"
            label-for="input-patient"
          >
            <treeselect
              v-model="form.patient_id"
              :multiple="false"
              :options="patients"
            />
            <small class="text-danger">{{ error.patient_id }}</small>
          </b-form-group> -->

          <!-- Title Input -->
          <b-form-group
            id="input-group-title"
            label="Judul:"
            label-for="input-title"
          >
            <b-form-input
              id="input-title"
              v-model="form.title"
              placeholder="Judul Dokumen"
            ></b-form-input>
            <small class="text-danger">{{ error.title }}</small>
          </b-form-group>

          <!-- Format -->
          <b-form-group
          id="input-group-blood-type"
          
        >
          <label for="input-email">Format File: <em class="text-muted">opsional</em></label>
          <b-form-select
            id="input-blood-type"
            v-model="form.format"
            :options="formats"
            required
          ></b-form-select>
            <small class="text-danger">{{ error.format }}</small>
        </b-form-group>

        <!-- description -->
        <b-form-group
          id="input-group-case"
         
        >
          <label for="input-email">Deskripsi: <em class="text-muted">opsional</em></label>
          <b-form-textarea
            id="textarea"
            v-model="form.description"
            placeholder=""
            rows="3"
            max-rows="6"
          ></b-form-textarea>
            <small class="text-danger">{{ error.description }}</small>
        </b-form-group>

          <!-- File Image -->
          <b-form-group
            id="input-group-file"
            label="Dokumen:"
            label-for="input-file"
          >
            <b-form-file
              multiple
              placeholder="Upload Dokumen"
              drop-placeholder="Drop file here..."
              @change="upload"
            ></b-form-file>
            <small class="text-danger">{{ error.file }}</small>
          </b-form-group>
        </b-form>
      </b-modal>
    </div>
    <Modal
      @chosenPatient="setPatient"
      @submitedPatient="setPatient"
    />
  </div>
</template>

<script>

import Modal from "@/component/general/ModalPatient.vue";
import module from '@/core/modules/CrudModule.js'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import validation from '@/core/modules/ValidationModule.js'

export default {

  components: {
    Treeselect,
    Modal
  },

  data() {
    return {
      // Form
      form: {
        patient_id: '',
        format:'',
        description:'',
        title: '',
        check_date:'',
      },
      // Error
      error: {
        patient_id: '',
        format:'',
        description:'',
        title: '',
        files: '',
        check_date:'',
      },
      // Options
      patients: [],
      formats: [
        { text: 'Pdf', value: 'pdf' },
        { text: 'Docx', value: 'docx' },
        { text: 'Xls', value: 'xls' },
        { text: 'Lainnya', value: 'other' }
        
      ],
      // data
      formData: new FormData(),
      // display
       display: {
        patient_name: this.$route.query.patient_name ?? '',
      },
    }
  },

  methods: {
     setPatient(value) {
      this.form.patient_id = value.id;
      this.display.patient_name = value.name;
      this.$bvModal.hide("modal-patient");
    },

    async getPatientsOption() {
      let response = await module.setTreeSelect('patients')
      // Success
      if (response.state == 'success') {
        // Set Option Select
        this.patients = response.data
        this.patients.unshift({ label: 'Pilih Pasien', id: '', isDisabled: true })
      }
    },

    upload(event) {
      if (event.target.files.length > 0) {
        this.formData = new FormData();
      }

      for (let a = 0; a <= event.target.files.length; a++) {
        this.formData.append("files[]", event.target.files[a]);
      }
    },

    async formOnSubmit() {
      
      for (const [key, value] of Object.entries(this.form)) {
        this.formData.append(key, value)
      }
      // Make Request
      let response = await module.submit(this.formData, 'documents')
      // Check Response
      if (response.state == 'error') {
        // Validation Error
        this.error = validation.setValidationError(this.error, response.error.errors)
        console.log(this.error)
      } else {
        // Success
        this.display.patient_name = ""
        Swal.fire(response.success.title, response.success.message, 'success')
        this.$bvModal.hide('modal-form')
        
      }
      
      this.$parent.getDocuments()
      // this.$parent.list()
    },

    modalOnSubmit(evt) {
      evt.preventDefault()
      this.formOnSubmit()
    },

    modalOnHide(evt) {
      this.error = validation.clearValidationError(this.error)
      this.form = validation.clearForm(this.form)
       this.$bvModal.hide('modal-form')
    }

  },

  mounted() {
    this.getPatientsOption()
  }

}
</script>

<style>
</style>